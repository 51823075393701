import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSpinner, 
    faCircleCheck, 
    faTriangleExclamation 
} from '@fortawesome/free-solid-svg-icons';
import styles from './TableStatusDisplay.module.css';

export function TableStatusDisplay() {
    const op = useRef(null);
    const tables = useSelector(state => state.tableLoading.tables);
    
    const hasPending = Object.values(tables).some(t => t.status === 'pending');
    const hasError = Object.values(tables).some(t => t.status === 'error');
    const allSuccess = Object.values(tables).every(t => t.status === 'success');

    const getStatusIcon = () => {
        if (hasPending) {
            return <FontAwesomeIcon icon={faSpinner} style={{ color: '#F4CA5E' }} spin />;
        }
        if (hasError) {
            return <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: '#FF7070' }} />;
        }
        return <FontAwesomeIcon icon={faCircleCheck} style={{ color: 'green' }} />;
    };

    const formatTime = (timestamp) => {
        if (!timestamp) return 'Never';
        return new Date(timestamp).toLocaleTimeString();
    };

    return (
        <>
            <Button 
                className={`p-button-text ${styles['status-button']}`}
                onClick={(e) => op.current.toggle(e)}
            >
                {getStatusIcon()}
            </Button>
            
            <OverlayPanel ref={op} className={styles['table-status-panel']}>
                <div className={styles['table-status-list']}>
                    <h3>Table Status</h3>
                    {Object.entries(tables).map(([name, table]) => (
                        <div key={name} className={styles['table-status-item']}>
                            <span className={styles['table-name']}>{name}</span>
                            <span className={styles['table-status']}>
                                {table.status === 'pending' && (
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                )}
                                {table.status === 'error' && (
                                    <FontAwesomeIcon 
                                        icon={faTriangleExclamation} 
                                        style={{ color: '#FF7070' }} 
                                    />
                                )}
                                {table.status === 'success' && (
                                    <FontAwesomeIcon 
                                        icon={faCircleCheck} 
                                        style={{ color: 'green' }} 
                                    />
                                )}
                            </span>
                            <span className={styles['table-time']}>
                                Last Updated: {formatTime(table.lastRefreshed)}
                            </span>
                        </div>
                    ))}
                </div>
            </OverlayPanel>
        </>
    );
} 