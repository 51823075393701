import { useEffect, lazy, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { omit, isEqual } from "lodash";

import { PageLayout } from "../../components/Nav";
import {
    db,
} from "../../utils/indexeddb/db";
import { forceRefreshAllTables } from "../../utils/redux/tableLoadingSlice";
import { setUser } from "../../utils/redux/loginSlice";
// import DataLoader from "./DataLoader";

const ProcessingListPage = lazy(() => import("../apps/NCTO/ProcessingListPage"));
const Home = lazy(() => import("../Home"));
const TeamReviews = lazy(() => import("../reports/hr/TeamReviews"))
const FirmReviews = lazy(() => import("../reports/hr/FirmReviews"))
const TeamMorale = lazy(() => import("../reports/hr/TeamMorale"))
const FirmMorale = lazy(() => import("../reports/hr/FirmMorale"))
const Member = lazy(() => import("../reports/powerbi/Member"))
const Morale = lazy(() => import("../people/Morale/Morale"))
const Review = lazy(() => import("../people/Review/Review"))
const Request = lazy(() => import("../people/Request/Request"))
const ReviewApproval = lazy(() => import("../people/Review/ReviewApproval"))
const NCTO = lazy(() => import("../apps/NCTO/NCTO"))
const NCTOLandingPage = lazy(() => import("../apps/NCTO/LandingPage"));
const Approval = lazy(() => import("../apps/NCTO/ApprovalPage/Approval"));
const ApprovalListPage = lazy(() =>
    import("../apps/NCTO/ApprovalPage/ApprovalListPage")
);
const BOI = lazy(() => import("../apps/NCTO/BOI"));
const Reject = lazy(() => import("../apps/NCTO/Reject"))

function RoutesContent(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector((state) => state.user);
    const bearer = useSelector((state) => state.user.bearer);
    const apiRoot = useSelector(state => state.globals.apiRoot);

    useEffect(() => {
        // Only run these API calls if we're on the root path
        if (location.pathname === '/') {
            fetch(`${apiRoot}/api/tracking/login/${user.STAFFINDEX}`, {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: bearer,
                },
            });

            const email = user.STAFF_EMAIL.toUpperCase().split("@");
            let addressSite = email[1].split(".");
            const username = email[0];
            const domain = addressSite[0].replace('BH-CO', 'BHCO');
            const ext = addressSite[1];

            fetch(`${apiRoot}/api/staff/me/${username}/${domain}/${ext}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "x-functions-key": "5cvbp_zFyZsTV9J8dqHD7NSkHHnUxyqR8dM0gSP5RYLfAzFuhVAZHQ==",
                    Authorization: bearer,
                    "Access-Control-Allow-Origin": "https://bhco.magnify-app.com/"
                },
            })
                .then(response => response.json())
                .then(fetchedUser => {
                    const currentUserCompare = omit(user, ['bearer', 'authenticated']);
                    const fetchedUserCompare = omit(fetchedUser, ['bearer', 'authenticated']);

                    if (!isEqual(currentUserCompare, fetchedUserCompare)) {
                        console.log('User data has changed. Updating user state.');
                        dispatch(setUser({
                            ...fetchedUser,
                            bearer: bearer,
                            authenticated: 'authenticated'
                        }));
                    }
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                });
        }
    }, []); // Still keeping empty dependency array for mount-only behavior

    return (
        <PageLayout setShowHelp={props.setShowHelp}>
            <Suspense fallback={<br></br> /*<Loading loader='ring' />*/}>
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={
                            <Home
                                // setGraph={props.setGraph}
                                // inProgress={props.inProgress}
                            />
                        }
                    />
                    <Route
                        exact
                        path='/reports/reviews/team'
                        element={<TeamReviews />}
                    />
                    <Route
                        exact
                        path='/reports/reviews/firm'
                        element={<FirmReviews />}
                    />

                    <Route
                        exact
                        path='/reports/morale/team'
                        element={<TeamMorale />}
                    />
                    <Route
                        exact
                        path='/reports/morale/firm'
                        element={<FirmMorale />}
                    />

                    <Route
                        exact
                        path="/reports/powerbi/member"
                        element={<Member />}
                    />
                    <Route exact path="/people/morale" element={<Morale />} />
                    <Route exact path="/people/reviews" element={<Review />} />
                    <Route exact path="/people/requests" element={<Request />} />
                    <Route exact path="/people/reviewapproval" element={<ReviewApproval />} />
                    {/* <Route exact path="/apps/bingo" element={<Bingo />} />  */}

                    <Route exact path="/apps/ncto" element={<NCTOLandingPage />} />
                    <Route exact path="/apps/ncto/form" element={<NCTO />} />
                    <Route
                        exact
                        path="/apps/ncto/approve/"
                        element={<ApprovalListPage />}
                    />
                    <Route
                        path="/apps/ncto/approve/:key"
                        element={<Approval />}
                    />
                    <Route
                        path='/apps/ncto/reject/:key'
                        element={<Reject />}
                    />
                    <Route exact path="/apps/ncto/boi" element={<BOI />} />
                    <Route exact path="/apps/ncto/process" element={<ProcessingListPage />} />
                </Routes>
            </Suspense>
        </PageLayout>
    );
}

export default function UserRouter(props) {
    const version = "3.0.2"
    const dispatch = useDispatch();

    useEffect(() => {

        const clearAllStorage = async () => {
            try {
                console.log('Starting storage clear process...');

                // Force refresh all tables first
                dispatch(forceRefreshAllTables());
                console.log('Forced refresh of all tables');

                // Clear IndexedDB first
                await db.delete();
                console.log('IndexedDB cleared');

                // Clear all localStorage items
                const specificKeys = ['tableLoading', 'lastRefreshed'];
                specificKeys.forEach(key => {
                    if (localStorage.getItem(key)) {
                        console.log(`Clearing ${key} from localStorage`);
                        localStorage.removeItem(key);
                    }
                });
                localStorage.clear();
                console.log('localStorage cleared');

                // Clear sessionStorage
                sessionStorage.clear();
                console.log('sessionStorage cleared');

                // Clear cache if possible
                if ('caches' in window) {
                    const cacheKeys = await caches.keys();
                    await Promise.all(
                        cacheKeys.map(key => caches.delete(key))
                    );
                    console.log('Browser caches cleared');
                }

                // Verify localStorage is actually clear
                const isLocalStorageCleared = localStorage.length === 0;
                console.log('localStorage is cleared:', isLocalStorageCleared);

                // Only set new version after confirming clear
                if (isLocalStorageCleared) {
                    localStorage.setItem('version', version);
                    console.log('New version set:', version);
                }

                // Add a delay to ensure all operations complete
                await new Promise(resolve => setTimeout(resolve, 500));

                // Final verification
                console.log('Final localStorage state:', {
                    tableLoading: localStorage.getItem('tableLoading'),
                    lastRefreshed: localStorage.getItem('lastRefreshed'),
                    version: localStorage.getItem('version')
                });



                // Reload page
                window.location.reload();
            } catch (error) {
                console.error('Error clearing storage:', error);
                // Attempt recovery
                try {
                    await db.delete();
                    localStorage.clear();
                    sessionStorage.clear();

                    // Explicit removal of problematic items
                    localStorage.removeItem('tableLoading');
                    localStorage.removeItem('lastRefreshed');

                    localStorage.setItem('version', version);

                    await new Promise(resolve => setTimeout(resolve, 500));
                    window.location.reload();
                } catch (secondError) {
                    console.error('Recovery attempt failed:', secondError);
                }
            }
        };

        // Only clear if versions don't match
        if (localStorage.getItem('version') !== version) {
            console.log(`Clearing storage, old version: ${localStorage.getItem('version')}`);
            clearAllStorage();
        }
    }, [dispatch]);


    // const authenticated = useSelector((state) => state.user.authenticated);
    // const user = useSelector((state) => state.user.STAFFINDEX);
    // const bearer = useSelector((state) => state.user.bearer);
    // const apiRoot = useSelector(state => state.globals.apiRoot)

    // useEffect(() => {
    //     fetch(`${apiRoot}/api/tracking/login/${user}`, {
    //         method: "PUT",
    //         headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json",
    //             Authorization: bearer,
    //         },
    //     });
    // }, []); // Perfect for this use case - single login tracking

    return (
        <Router>
            {/* <DataLoader /> */}
            <RoutesContent setShowHelp={props.setShowHelp} />
        </Router>
    );
}