import { Fragment } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { useSelector } from "react-redux";

export default function NCTONav() {
    const user = useSelector(state => state.user)
    // console.log(user)
    const { BOI, NCTO_APPROVE, NCTO_COMPLETE } = user
    // const top_BILL = useSelector(state => state.TOP_BILL)
    // const dept_BILL = useSelector(state => state.DEPT_BILL)
    // const responsible_BILL = useSelector(state => state.RESPONSIBLE_BILL)
    // const department = useSelector(state => state.DEPARTMENT)

    // if (BOI === 1 || NCTO_APPROVE) {
    return (
    <Fragment>
        <Dropdown.Item href="/apps/ncto">New Client Take On</Dropdown.Item>
        {BOI === 1 ? <Dropdown.Item href="/apps/ncto/boi">BOI List</Dropdown.Item> : <Fragment></Fragment>}
        {NCTO_APPROVE ? <Dropdown.Item href="/apps/ncto/approve">Client Approval List</Dropdown.Item> : <Fragment></Fragment>}
        {NCTO_COMPLETE ? <Dropdown.Item href="/apps/ncto/process">Clients to be Processed List</Dropdown.Item> : <Fragment></Fragment>}
        {/* {NCTO_APPROVE ? <Dropdown.Item href={`/apps/ncto/approve/${NCTO_APPROVE.toLowerCase().replace(' ', '-')}`}>{NCTO_APPROVE} Approval List</Dropdown.Item> : <Fragment></Fragment>} */}
        {/* <Dropdown.Divider /> */}
    </Fragment>
    
    )
    // } else {
    //     <Fragment></Fragment>
    // }
}