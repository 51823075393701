import React, { useEffect, useState } from "react";
// import { PageLayout } from "./components/Nav";
import { PrimeReactProvider } from "primereact/api";
import { Help } from "./components/Help";
// import { isEqual } from 'lodash'

import "./style/primereact-theme.css";
import "./style/App.css";
import "./style/theme.css";
import "./style/static-ar-card.css"
import "/node_modules/primeflex/primeflex.css";
import "/node_modules/bootstrap/dist/css/bootstrap.css";

// import { useLocation } from "react-router-dom";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import {  InteractionType } from "@azure/msal-browser";
import { useSelector } from "react-redux";
// import { setUser } from "./utils/redux/loginSlice";
// import { callMsGraph } from "./graph";

// import ClipLoader from "react-spinners/ClipLoader";

import NonUserPage from "./pages/home/NonUserPage";
import UserRouter from "./pages/home/UserRouter";
import { Loading } from "./pages/home/Loading";

// import {
//     DataRefresher,
//     db,
//     GetRemoteVersions,
//     //   UpdateLessChangelog,
// } from "./utils/DataRefresher";

import { loginRequest } from "./authConfig";
// import { DeptWIP, TeamWIP } from "./pages/reports/wip/WIPreports";
// import RespWIP from "./pages/reports/wip/RespWIP";
// import { MyWIP } from "./pages/reports/wip/MyWIP";

// function ActiveUserRoute(props) {
//     const version = "2.0.5"

//     useEffect(() => {
//         const clearAllStorage = async () => {
//             try {
//                 // Clear localStorage and sessionStorage
//                 localStorage.clear();
//                 sessionStorage.clear();
                
//                 // Clear IndexedDB
//                 await db.delete(); // Assuming db is your Dexie instance
                
//                 // Set new version
//                 localStorage.setItem('version', version);
                
//                 // Reload page
//                 window.location.reload();
//             } catch (error) {
//                 console.error('Error clearing storage:', error);
//                 // Attempt recovery
//                 localStorage.clear();
//                 sessionStorage.clear();

//                 await db.delete();

//                 localStorage.setItem('version', version);
//                 window.location.reload();
//             }
//         };

//         // Only clear if versions don't match
//         if (localStorage.getItem('version') !== version) {
//             console.log(`Clearing storage, old version: ${localStorage.getItem('version')}`);
//             clearAllStorage();
//         }
//     }, []); // Empty dependency array means this only runs once on mount


//     const authenticated = useSelector((state) => state.user.authenticated);
//     const user = useSelector((state) => state.user.STAFFINDEX);
//     const bearer = useSelector((state) => state.user.bearer);
//     const apiRoot = useSelector(state => state.globals.apiRoot)

//     if (authenticated === 'authenticated' || authenticated === true) {
//         fetch(`${apiRoot}/api/tracking/login/${user}`, {
//             method: "PUT",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//                 Authorization: bearer,
//             },
//         })

//         return (
//             <Router>
//                 <Loading dataRefreshingState={props.dataRefreshingState} />
//                 <PageLayout setShowHelp={props.setShowHelp}>
//                     <Suspense fallback={<br></br> /*<Loading loader='ring' />*/}>
//                         <Routes>
//                             <Route
//                                 exact
//                                 path="/"
//                                 element={
//                                     <Home
//                                         setGraph={props.setGraph}
//                                         inProgress={props.inProgress}
//                                     />
//                                 }
//                             />
//                             {/* <Route exact path="/reports" element={<Reports />} />
//                             <Route exact path="/reports/ar/firm" element={<FirmAR />} />
//                             <Route exact path="/reports/ar/dept" element={<DeptAR />} />
//                             <Route
//                                 exact
//                                 path="/reports/ar/responsible"
//                                 element={<RespAR />}
//                             />

//                             <Route
//                                 exact
//                                 path="/reports/wip/responsible"
//                                 element={<RespWIP />}
//                             />
//                             <Route exact path="/reports/wip/self" element={<MyWIP />} />
//                             <Route exact path="/reports/wip/dept" element={<DeptWIP />} />
//                             <Route exact path="/reports/wip/team" element={<TeamWIP />} /> */}
//                             <Route
//                                 exact
//                                 path='/reports/reviews/team'
//                                 element={<TeamReviews />}
//                             />
//                             <Route
//                                 exact
//                                 path='/reports/reviews/firm'
//                                 element={<FirmReviews />}
//                             />

//                             <Route
//                                 exact
//                                 path='/reports/morale/team'
//                                 element={<TeamMorale />}
//                             />
//                             <Route
//                                 exact
//                                 path='/reports/morale/firm'
//                                 element={<FirmMorale />}
//                             />

//                             <Route
//                                 exact
//                                 path="/reports/powerbi/member"
//                                 element={<Member />}
//                             />
//                             <Route exact path="/people/morale" element={<Morale />} />
//                             <Route exact path="/people/reviews" element={<Review />} />
//                             <Route exact path="/people/requests" element={<Request />} />
//                             <Route exact path="/people/reviewapproval" element={<ReviewApproval />} />
//                             {/* <Route exact path="/apps/bingo" element={<Bingo />} />  */}

//                             <Route exact path="/apps/ncto" element={<NCTOLandingPage />} />
//                             <Route exact path="/apps/ncto/form" element={<NCTO />} />
//                             <Route
//                                 exact
//                                 path="/apps/ncto/approve/"
//                                 element={<ApprovalListPage />}
//                             />
//                             <Route
//                                 path="/apps/ncto/approve/:key"
//                                 element={<Approval />}
//                             />
//                             <Route
//                                 path='/apps/ncto/reject/:key'
//                                 element={<Reject />}
//                             />
//                             <Route exact path="/apps/ncto/boi" element={<BOI />} />
//                             <Route exact path="/apps/ncto/process" element={<ProcessingListPage />} />

//                             {/* <Route exact path='/reports/powerbi/hr' Component={} /> */}
//                         </Routes>
//                     </Suspense>
//                 </PageLayout>
//             </Router>
//         );
//     } else if (authenticated === 'failed') {
//         return (
//             <section>
//                 <p>Uh oh! Looks like you are not a registered user with this company</p>
//                 <button onClick={() => {
//                     localStorage.clear()
//                     sessionStorage.clear()
//                     window.location.reload()
//                 }}>Click here to reset and login again</button>
//             </section>
//         );
//     } else {
//         return (<>
//             <ClipLoader loading={true} />
//             {/* <Help visible={showHelp} showCallBack={setShowHelp} /> */}
//         </>)
//     }
// }

export default function App() {
    // const [graphData, setGraphData] = useState(null);
    const [showHelp, setShowHelp] = useState(false);
    // const { instance, inProgress } = useMsal();
    const USER = useSelector((state) => state.user);
    const [authStatus, setAuthStatus] = useState(USER.authenticated || 'pending');
    // const bearer = useSelector((state) => state.user.bearer);
    // const apiRoot = useSelector(state => state.globals.apiRoot)
    // const dispatch = useDispatch();
    // const [dataRefreshingState, setDataRefreshingState] = useState(false);

    useEffect(() => {
        setAuthStatus(USER.authenticated || 'pending');
    }, [USER.authenticated]);

    return (
        <PrimeReactProvider>
            {authStatus === 'failed' ? (
                <>
                    <NonUserPage 
                        // dataRefreshingState={dataRefreshingState}
                        setShowHelp={setShowHelp}
                    />
                    <Help visible={showHelp} showCallBack={setShowHelp} />
                </>
            ) : authStatus === 'pending' ? (
                <MsalAuthenticationTemplate 
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={loginRequest}
                >
                    <Loading />
                </MsalAuthenticationTemplate>
            ) : authStatus === 'authenticated' ? (
                <>
                    <UserRouter
                        // setGraph={setGraphData}
                        // inProgress={inProgress}
                        setShowHelp={setShowHelp}
                        // dataRefreshingState={dataRefreshingState}
                    />
                    <Help visible={showHelp} showCallBack={setShowHelp} />
                </>
            ) : null}
        </PrimeReactProvider>
    );
}
