import { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { NonUserLayout } from "../../components/Nav";

function Home() {
    return (
        <section style={{ textAlign: 'center', marginTop: '100px' }}>
            <p>Uh oh! Looks like you are not a registered user with this company</p>
            <button onClick={() => {
                localStorage.clear()
                sessionStorage.clear()
                window.location.reload()
            }}>Click here to reset and login again</button>
        </section>
    );
}

export default function NonUserPage(props) {
    return (
        <Router>
            <NonUserLayout setShowHelp={props.setShowHelp}>
                <Suspense fallback={<br></br> /*<Loading loader='ring' />*/}>
                    <Routes>
                        <Route
                            exact
                            path="/"
                            element={
                                <Home />
                            }
                        />
                    </Routes>
                </Suspense>
            </NonUserLayout>
        </Router>
    );
}