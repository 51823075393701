export const localTables = {
    // Format: 'primaryKey,indexedColumn1,indexedColumn2'
    
    CHANGE_LOG: 'DATA_TABLE,HASH_VALUE',

    CHECKINS: 'INDEX,DATE',
    
    CONTACTS: 'INDEX, NAME',

    COUNTRIES: 'INDEX',
    
    ENGAGEMENT: 'INDEX,CLIENTSTATUS',
    
    ENTERED_CLIENTS: 'KEY,STATUS,SUBMITTER,CLIENT_NAME,CLIENT_BOI',

    ENTITY: 'INDEX,NAME',

    HOMEROOM: 'INDEX,EMPLOYEE',

    INDUSTRIES: 'INDEX,NAME',

    JOBS: 'INDEX,JOB_TYPE',

    JOB_TEMPLATES: 'INDEX,TYPE',
    
    MORALE: 'INDEX,DATE,DEPARTMENT,LEVEL,WEEK,STAFFNAME,STAFF',
    
    NEW_CLIENTS: 'requestNumber,status,requestingUser',

    OFFICES: 'INDEX,NAME',

    REASONS: 'INDEX,NAME',
    
    REQUESTS: 'INDEX,REQUEST_STATUS,SUBMIT_USER,REQUEST_USER,WEEK',
    
    REVIEWS: 'INDEX,REVIEW_STATUS,REVIEW_USER,SUBMIT_USER,WEEK',

    SERVICES: 'INDEX,SERVNAME,SERVPERSON,SERVORGANISATION',

    SOURCES: 'INDEX,NAME',
    
    STAFF: 'INDEX,STAFFINDEX,IS_MANAGER,CLIENT_RESPONSIBLE,EMPLOYEE,DEPARTMENT,LEVEL,HOMEROOM,TEAM',
    
    STATIC: 'FOCUS',

    USER_WIP: 'INDEX',
};
