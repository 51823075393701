import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tables: {},
  isInitialized: false
};

export const tableLoadingSlice = createSlice({
  name: 'tableLoading',
  initialState,
  reducers: {
    setTableStatus: (state, action) => {
      const { tableName, status, errorMessage } = action.payload;
      state.tables[tableName] = {
        ...state.tables[tableName],
        status,
        lastRefreshed: new Date().toISOString(),
        errorMessage: errorMessage || undefined,
        loadingComplete: status === 'success'
      };
    },
    initializeDefaultTables: (state, action) => {
      const defaultTables = action.payload;
      defaultTables.forEach(table => {
        if (!state.tables[table.tableName]) {
          state.tables[table.tableName] = {
            ...table,
            status: 'pending',
            lastRefreshed: null,
            isDefault: true,
            loadingComplete: false,
            staleDataChecked: false
          };
        }
      });
      state.isInitialized = true;
    },
    addRequiredTable: (state, action) => {
      const { tableName, endpoint, isLocalOnly } = action.payload;
      if (!state.tables[tableName]) {
        state.tables[tableName] = {
          endpoint,
          status: isLocalOnly ? 'success' : 'pending',
          lastRefreshed: isLocalOnly ? new Date().toISOString() : null,
          isDefault: false,
          loadingComplete: isLocalOnly ? true : false,
          isLocalOnly: isLocalOnly || false,
          staleDataChecked: false
        };
      }
    },
    refreshTable: (state, action) => {
      const { tableName } = action.payload;
      if (state.tables[tableName]) {
        state.tables[tableName] = {
          ...state.tables[tableName],
          status: 'pending',
          loadingComplete: false
        };
      }
    },
    refreshStaleData: (state, action) => {
      const tableName = action.payload;
      const TWO_HOURS = 2 * 60 * 60 * 1000;
      const now = new Date().getTime();

      // Tables that should always check for stale data
      const alwaysCheckTables = [
        'ENGAGEMENT',
        'ENTERED_CLIENTS',
        'REVIEWS',
        'REQUESTS',
        'CHECKINS',
        'MORALE',
      ];

      if (state.tables[tableName]) {
        const table = state.tables[tableName];
        const lastRefreshed = new Date(table.lastRefreshed || 0).getTime();
        
        if (!table.staleDataChecked && 
            (alwaysCheckTables.includes(tableName) || now - lastRefreshed > TWO_HOURS)) {
          state.tables[tableName] = {
            ...table,
            status: 'pending',
            loadingComplete: false
          };
        }
        
        // Mark as checked regardless of whether it was stale
        state.tables[tableName].staleDataChecked = true;
      }
    },
    resetStaleCheck: (state, action) => {
      const tableName = action.payload;
      if (state.tables[tableName]) {
        state.tables[tableName].staleDataChecked = false;
      }
    },
    forceRefreshAllTables: (state) => {
      Object.keys(state.tables).forEach(tableName => {
        if (!state.tables[tableName].isLocalOnly) {  // Don't refresh local-only tables
          state.tables[tableName] = {
            ...state.tables[tableName],
            status: 'pending',
            loadingComplete: false
          };
        }
      });
    }
  }
});

export const { 
  setTableStatus, 
  initializeDefaultTables, 
  addRequiredTable,
  refreshTable,
  refreshStaleData,
  resetStaleCheck,
  forceRefreshAllTables 
} = tableLoadingSlice.actions;

export default tableLoadingSlice.reducer; 