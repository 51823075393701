import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./loginSlice";
import dataReducer from "./dataSlice";
import globalsReducer from './globalsSlice'
import tableLoadingReducer from './tableLoadingSlice'
import storage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/es/storage/session";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const rootPersistConfig = {
  key: "root",
  storage: sessionStorage,
  blacklist: ['user']
};

const userPersistConfig = {
  key: "user",
  storage,
};

const tableLoadingPersistConfig = {
    key: "tableLoading",
    storage: sessionStorage,
    transforms: [{
      in: (state, key) => {
        // Check if this is a new browser session
        const lastSessionTime = sessionStorage.getItem('lastSessionTimestamp');
        const currentTime = Date.now();
        
        // If no timestamp exists or it's from a previous session, reset the state
        if (!lastSessionTime) {
          sessionStorage.setItem('lastSessionTimestamp', currentTime);
          return {
            tables: {},
            isInitialized: false
          };
        }
        
        // If it's the same session, keep the state
        return state;
      },
      out: (state) => {
        // Update timestamp on state changes
        sessionStorage.setItem('lastSessionTimestamp', Date.now());
        return state;
      }
    }]
  };

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  data: dataReducer,
  globals: globalsReducer,
  tableLoading: persistReducer(tableLoadingPersistConfig, tableLoadingReducer)
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
