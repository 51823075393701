import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

import ClipLoader from "react-spinners/ClipLoader";

import { callMsGraph } from "../../graph";
import { setUser } from "../../utils/redux/loginSlice";

export function Loading() {

    const [graphData, setGraphData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { instance, inProgress } = useMsal();
    const bearer = useSelector((state) => state.user.bearer);
    const apiRoot = useSelector(state => state.globals.apiRoot)
    const dispatch = useDispatch();

    useEffect(() => {
        console.log("Loading useEffect - inProgress:", inProgress, "graphData:", graphData);
        
        if (!graphData && inProgress === InteractionStatus.None) {
            instance
                .handleRedirectPromise()
                .then((tokenResponse) => {
                    if (tokenResponse) {
                        callMsGraph(tokenResponse.accessToken)
                            .then((response) => {
                                setGraphData(response);
                            })
                            .then(() => {
                                let email = tokenResponse.account.username.toUpperCase();
                                if (email.includes("@ABACUSTECHNOLOGIES.COM")) {
                                    email = prompt("Enter an email address to mask as a BHCO employee", "suzanneb@bh-co.com");
                                    if (!email || email === "") {
                                        email = "suzanneb@bh-co.com".toUpperCase();
                                    } else {
                                        email = email.toUpperCase();
                                    }
                                } else if (email === 'JSHANK@MAGNIFYAPPONLINE.ONMICROSOFT.COM') {
                                    email = "GENNYC@bh-co.com".toUpperCase()
                                } 

                                email = email.split("@");
                                let addressSite = email[1].split(".");
                                const user = email[0];
                                const domain = addressSite[0].replace('BH-CO', 'BHCO');
                                const ext = addressSite[1];

                                fetch(`${apiRoot}/api/staff/me/${user}/${domain}/${ext}`, {
                                    method: "GET",
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                        "x-functions-key":
                                            "5cvbp_zFyZsTV9J8dqHD7NSkHHnUxyqR8dM0gSP5RYLfAzFuhVAZHQ==",
                                        Authorization: bearer,
                                    }
                                }).then(res => {
                                    if (!res.ok) {  // Check if status is not 200-299
                                        throw new Error(`HTTP status ${res.status}`);
                                    }
                                    return res.json();
                                }).then((data) => {
                                    dispatch(
                                        setUser({ authenticated: 'authenticated', ...data, bearer: bearer })
                                    );
                                }).catch((err) => {
                                    console.error("Authentication failed:", err);
                                    dispatch(
                                        setUser({
                                            authenticated: 'failed',
                                            message:
                                                "Uh oh! Looks like you are not a registered user with this company!",
                                        })
                                    );
                                }).finally(() => {
                                    setLoading(false);
                                });
                            });
                    } else {
                        throw new Error("No token response!");
                    }
                })
                .catch((err) => {
                    console.error("Redirect promise error:", err);
                });
        }
    }, [inProgress, graphData, instance, dispatch, bearer, apiRoot]);

    return (
        <>
            <div style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                zIndex: 1000,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <ClipLoader
                    color="Black"
                    loading={loading}
                    size={50}
                    aria-label="Loading"
                    data-testid="loader"
                />
            </div>
        </>
    );
};